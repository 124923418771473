import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

ReactDOM.render(
   <BrowserRouter>
      <HelmetProvider>
         <React.StrictMode>
            <App />
         </React.StrictMode>
      </HelmetProvider>
   </BrowserRouter>,
   document.getElementById("root")
);

reportWebVitals();
