import { useForm } from "react-hook-form";
import { useState, useRef, useEffect } from "react";
import "./contact.css";
import Transition from "../transitionPage/transitionPage";
import { CgAsterisk } from "react-icons/cg";
import emailjs from "@emailjs/browser";
import { Helmet } from "react-helmet-async";
import gsap from "gsap";

export default function Contact() {
   const transiContact = gsap.timeline();
   const contactRef = useRef(null);

   useEffect(() => {
      contactGsap();
      return () => contactGsap();
   }, []);

   function contactGsap() {
      gsap.fromTo(
         contactRef.current,
         { autoAlpha: 0 },
         {
            autoAlpha: 1,
            duration: 1.7,
            delay: 1,
         }
      );
   }

   // Variables
   const {
      register,
      handleSubmit,
      reset,
      formState: { errors },
   } = useForm();

   // States
   const [isLoading, setIsLoading] = useState(false);
   const [isSended, setIsSended] = useState(false);

   const templateParams = (data) => {
      let params = {
         prenom: data.prenom,
         nom: data.nom,
         mobileNumber: data.mobileNumber,
         email: data.email,
         select: data.select,
         contenu: data.contenu,
         cg: data.cg,
      };
      return params;
   };

   const userID = process.env.REACT_APP_USER_ID;

   const onSubmit = (data) => {
      if (!isLoading) {
         setIsLoading(true);
         emailjs
            .send(
               "service_53hff8y",
               "template_ehntha4",
               templateParams(data),
               userID
            )
            .then((res) => {
               console.log(res);
            })
            .catch((err) => console.log(err));
      }
      setIsLoading(false);
      reset();
      setIsSended(true);
   };

   return (
      <>
         <Transition timeline={transiContact} />
         <div className='pageContact' ref={contactRef}>
            <Helmet>
               <title>VOU - Contactez-nous</title>
               <meta
                  name='description'
                  content='Vous avez un projet ? Contactez-nous '
               />
               <meta
                  name='keywords'
                  content='Contact, Projet, Print, Digital, Site web, Réseaux sociaux, Design'
               />
            </Helmet>
            <div className='telContact'>
               <h1>
                  Vous avez un projet ? <br /> Contactez-nous
               </h1>
               <h2>Par téléphone au :</h2>
               <a href='tel:+330652856675'>06 52 85 66 75</a>
               <p>
                  Idéalement, nous avons besoin des informations ci-dessous pour
                  chiffrer votre projet :
               </p>
               <ul>
                  <li>Votre cahier des charges s'il existe.</li>
                  <li>Une description de votre projet.</li>
                  <li>Des exemples de sites concurrents.</li>
                  <li>Le détail des fonctionnalités majeures.</li>
                  <li>La date de livraison souhaitée.</li>
               </ul>
            </div>

            <div className='formContact'>
               <h2>Ou renseignez le formulaire de contact :</h2>
               <form onSubmit={handleSubmit(onSubmit)}>
                  {isSended && (
                     <p style={{ color: "#fab634" }}>
                        Votre message a bien été envoyé. Merci, nous vous
                        contacterons le plus rapidement possible !
                     </p>
                  )}

                  <div className='txtField'>
                     <input
                        type='text'
                        className='input'
                        id='prenom'
                        {...register("prenom", {
                           required: true,
                        })}
                        required
                     />
                     <span />
                     <label className='label' forhtml='prenom'>
                        Prénom
                        <CgAsterisk />
                     </label>
                     {errors.prenom && (
                        <p className='errors'>
                           Vous devez renseigner votre prénom.
                        </p>
                     )}
                  </div>

                  <div className='txtField'>
                     <input
                        type='text'
                        className='input'
                        id='nom'
                        {...register("nom", {
                           required: true,
                        })}
                        required
                     />
                     <span />
                     <label className='label' forhtml='nom'>
                        Nom
                        <CgAsterisk />
                     </label>
                     {errors.nom && (
                        <p className='errors'>
                           Vous devez renseigner votre nom.
                        </p>
                     )}
                  </div>

                  <div className='txtField'>
                     <input
                        className='input'
                        id='email'
                        {...register("email", {
                           required: true,
                           pattern: /^\S+@\S+$/i,
                        })}
                        required
                     />
                     <span />
                     <label className='label' forhtml='email'>
                        Adresse email
                        <CgAsterisk />
                     </label>
                     {errors.email && (
                        <p className='errors'>
                           Vous devez renseigner votre adresse email.
                        </p>
                     )}
                  </div>

                  <div className='txtField'>
                     <input
                        className='input'
                        id='tel'
                        {...register("mobileNumber", {
                           required: true,
                           minLength: 6,
                           maxLength: 13,
                        })}
                        required
                     />
                     <span />
                     <label className='label' forhtml='tel'>
                        Téléphone
                        <CgAsterisk />
                     </label>
                     {errors.mobileNumber && (
                        <p className='errors'>
                           Vous devez renseigner votre numéro de téléphone.
                        </p>
                     )}
                  </div>

                  <div className='txtField'>
                     <label forhtml='select' className='labelSelect'>
                        Votre projet concerne : <CgAsterisk />
                     </label>

                     <select
                        className='input selectForm'
                        name='select'
                        {...register("select", { required: true })}
                     >
                        <option value='Digital'>
                           Digital (création de logo, charte graphique,
                           illustration..)
                        </option>
                        <option value='Web-design'>
                           Web-design (création d'un site internet, refonte,
                           maintenance..)
                        </option>
                        <option value='Print'>
                           Print ( flyer, carte de visite, brochure
                           commerciale..)
                        </option>
                        <option value='Réseaux sociaux'>
                           Réseaux sociaux (community management, Campagne
                           ADS..)
                        </option>
                        <option value='Prendre un café'>
                           Parler de votre projet autour d'un café
                        </option>
                     </select>
                  </div>

                  <div className='txtarea'>
                     <label className='areaLabel' forhtml='contenu'>
                        Message
                        <CgAsterisk />
                     </label>
                     <textarea
                        className='inputArea'
                        rows='9'
                        cols='60'
                        placeholder=' Bonjour... :)'
                        {...register("contenu", {
                           required: true,
                        })}
                     />
                     {errors.contenu && (
                        <p className='errors'>
                           Vous devez renseigner le contenu de votre message.
                        </p>
                     )}
                  </div>

                  <div className='checkbox'>
                     <input
                        {...register("cg", { required: true })}
                        type='checkbox'
                        name='cg'
                        value='Acceptation des conditions générales'
                        required
                     />
                     <label forhtml='cg'>
                        En cochant cette case, je reconnais avoir pris
                        connaissance des CGU du site ainsi que sa Politique de
                        Confidentialité
                     </label>
                     {errors.cg && (
                        <p className='errors'>
                           Veuillez accepter la condition.
                        </p>
                     )}
                  </div>

                  <div className='button'>
                     {!isLoading && <button type='submit'>Envoyer</button>}
                  </div>
               </form>
            </div>
         </div>
      </>
   );
}
