import { useState, useRef, useLayoutEffect } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import "./condition.css";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Helmet } from "react-helmet-async";
import gsap from "gsap";

export default function Condition() {
   const [numPages, setNumPages] = useState(null);
   const [pageNumber, setPageNumber] = useState(1);
   const conditionRef = useRef(null);

   useLayoutEffect(() => {
      conditionGsap();
      return () => conditionGsap();
   }, []);

   function conditionGsap() {
      gsap.fromTo(
         conditionRef.current,
         { autoAlpha: 0 },
         {
            autoAlpha: 1,
            duration: 2,
         }
      );
   }

   function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
      setPageNumber(1);
   }

   const changePage = (offset) => {
      setPageNumber((prevPageNumber) => prevPageNumber + offset);
   };

   const changeBackPage = () => {
      changePage(-1);
   };
   const changeNextPage = () => {
      changePage(+1);
   };

   return (
      <>
         <div className='conditionsPdf' ref={conditionRef}>
            <Helmet>
               <title>VOU - Conditions générales</title>
               <meta
                  name='description'
                  content='Nos conditions générales de ventes'
               />
               <meta name='keywords' content='conditions générales, Pdf' />
               <meta content='NOINDEX' name='robots' />
            </Helmet>
            <Document
               className='pdf'
               file='CGVVOU.pdf'
               onLoadSuccess={onDocumentLoadSuccess}
            >
               <Page className='pdfPage' pageNumber={pageNumber} />
               <p>
                  Page {pageNumber} of {numPages}
               </p>
               <br />
            </Document>
            {pageNumber > 1 && (
               <button className='buttonLeft' onClick={changeBackPage}>
                  <AiOutlineArrowLeft />
               </button>
            )}
            {pageNumber < numPages && (
               <button className='buttonRight' onClick={changeNextPage}>
                  <AiOutlineArrowRight />
               </button>
            )}
            <a href='CGVVOU.pdf' download>
               Cliquer pour télécharger
            </a>
         </div>
      </>
   );
}
