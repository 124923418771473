import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (children) => {
   const location = useLocation();

   useEffect(() => {
      window.scrollTo(0, 0);
      return () => window.scrollTo();
   }, [location]);

   return <>{children.children}</>;
};

export default ScrollToTop;
