import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./404.css";
import { Helmet } from "react-helmet-async";

export default function Error404() {
   const navigate = useNavigate();
   useEffect(() => {
      setTimeout(() => {
         navigate("/");
      }, 3000);
   }, [navigate]);

   return (
      <>
         <Helmet>
            <meta name='robots' content='noindex' />
            <meta
               name='description'
               content='Agence VOU est une agence de communication qui réunit vos besoins graphiques en print et en digital. 
         Notre spécialité ? Créer un univers de marque complet adapté à votre entreprise'
               data-rh='true'
            />
            <title>VOU - ERROR404</title>
         </Helmet>
         <div className='pageError'>
            <div className='container404'>
               <h2 className='oups'>Oups, c'est pas le bon chemin</h2>
            </div>
         </div>
      </>
   );
}
