import { useRef, useLayoutEffect } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import gsap from "gsap";

function Header() {
  const banner = useRef(null);

  useLayoutEffect(() => {
    bannerGsap();
    return () => bannerGsap();
  }, []);

  function bannerGsap() {
    gsap.fromTo(
      banner.current.querySelectorAll(".banner"),
      { autoAlpha: 0 },
      {
        autoAlpha: 1,
        duration: 1.7,
        delay: 0.5,
        stagger: 1,
      }
    );
  }

  return (
    <>
      <header ref={banner}>
        <Helmet>
          <title>VOU - Agence de communication digitale Parisienne</title>
          <meta
            name='description'
            content='Agence VOU est une agence de communication qui réunit vos besoins graphiques en print et en digital. 
         Notre spécialité ? Créer un univers de marque complet adapté à votre entreprise'
            data-rh='true'
          />
        </Helmet>
        <div className='title banner'>
          <div className='centerTitle'>
            <h1>
              L'histoire et les valeurs de votre entreprise au centre de notre
              interêt
            </h1>
            <a href='https://zadc1lvviun.typeform.com/to/H0nIMg59'>
              DEMANDER UN DEVIS
            </a>
          </div>
        </div>
        <div className='secondTitle banner'>
          <img
            src='./assets/simpleLogoVou.svg'
            alt='logo VOU'
            width='auto'
            height='auto'
            title='Agence VOU'
            loading='eager'
          />
          <div className='centerSecondTitle'>
            <h2>
              VOTRE MARQUE EST UN REPÈRE POUR VOUS MAIS ÉGALEMENT POUR VOS
              CLIENTS
            </h2>
            <p>
              C’EST À VOTRE IDENTITÉ VISUELLE QU’ILS VOUS <br />
              IDENTIFIENT, CELLE À LAQUELLE ILS S’ATTACHENT
            </p>
            <Link to='/contact'>
              <button>
                PARLONS-EN
                <img
                  src='./assets/phone.svg'
                  alt='icone téléphone'
                  width='auto'
                  height='auto'
                  loading='eager'
                  title=''
                />
              </button>
            </Link>
          </div>
        </div>
      </header>
    </>
  );
}
export default Header;
