import "./nav.css";
import { Link } from "react-router-dom";
import { GrContactInfo } from "react-icons/gr";
import gsap from "gsap";
import { useRef, useEffect, useLayoutEffect } from "react";

export default function Nav() {
   const navRef = useRef(null);
   const lineRef = useRef(null);

   useLayoutEffect(() => {
      navGsap();
      return () => navGsap();
   }, []);
   useEffect(() => {
      lineGsap();
      return () => lineGsap();
   }, []);

   function navGsap() {
      gsap.fromTo(
         navRef.current.querySelectorAll(".topNav"),
         { y: -50, autoAlpha: 0 },
         {
            y: 0,
            duration: 1,
            autoAlpha: 1,
         }
      );
   }

   function lineGsap() {
      gsap.fromTo(
         lineRef.current,
         { scale: 0 },
         {
            scale: 1,
            duration: 1.1,
         }
      );
   }

   return (
      <>
         <div className='line' ref={lineRef} />
         <nav className='nav' ref={navRef}>
            <div className='logo topNav'>
               <Link to='/'>
                  <img
                     src='assets/logoVou.svg'
                     alt='logo VOU'
                     width='auto'
                     height='auto'
                     title='Agence VOU'
                     loading='eager'
                  />
               </Link>
            </div>
            <div className='contactNav topNav'>
               <Link to='/contact'>
                  <GrContactInfo value={{ color: "white" }} /> CONTACT
               </Link>
            </div>
         </nav>
      </>
   );
}
