import "./App.css";
import Nav from "./components/nav/nav.js";
import Story from "./components/body/story";
import Contact from "./components/contact/contact";
import Footer from "./components/footer/footer";
import Condition from "./components/condition/condition";
import Error from "./components/404/404";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/Scrolltotop";
import gsap from "gsap";

function App() {
   gsap.config({
      nullTargetWarn: false,
   });
   return (
      <div className='App'>
         <Nav />
         <ScrollToTop>
            <Routes>
               <Route exact path='/' element={<Story />} />
               <Route exact path='/contact' element={<Contact />} />
               <Route
                  exact
                  path='/conditions-generales'
                  element={<Condition />}
               />
               <Route path='*' element={<Error />} />
            </Routes>
         </ScrollToTop>
         <Footer />
      </div>
   );
}

export default App;
