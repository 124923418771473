import "./story.css";
import ReactPlayer from "react-player";
import { useState, useEffect, useRef, useCallback } from "react";
import Header from "./header";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function Story() {
   const [playVou, setPlayVou] = useState(false);
   const videoRef = useRef(null);
   const liRef = useRef(null);
   const leftPartRef = useRef(null);
   const imageStoryRef = useRef(null);

   const videoGsap = useCallback(() => {
      gsap.fromTo(
         videoRef.current,
         {},
         {
            playingVideo,
            scrollTrigger: {
               trigger: videoRef.current,
               start: "top 60%",
            },
         }
      );
   }, []);

   const liGsap = useCallback(() => {
      gsap.fromTo(
         liRef.current.querySelectorAll("li"),
         { autoAlpha: 0, skewX: 5, x: 25 },
         {
            autoAlpha: 1,
            skewX: 0,
            x: 0,
            duration: 2,
            stagger: 0.5,
            scrollTrigger: {
               trigger: liRef.current.querySelectorAll("li"),
               start: "top 60%",
            },
         }
      );
   }, []);

   const storyGsap = useCallback(() => {
      gsap.fromTo(
         leftPartRef.current,
         { autoAlpha: 0, skewX: -5, x: -25 },
         {
            autoAlpha: 1,
            skewX: 0,
            x: 0,
            duration: 2,
            stagger: 0.5,
            scrollTrigger: {
               trigger: leftPartRef.current,
               start: "top 75%",
            },
         }
      );
   }, []);

   const imageGsap = useCallback(() => {
      gsap.fromTo(
         imageStoryRef.current,
         { autoAlpha: 0, clipPath: "circle(5% at 100% 50%)" },
         {
            autoAlpha: 1,
            clipPath: "circle(100% at 50% 50%)",
            duration: 2,
            scrollTrigger: {
               trigger: imageStoryRef.current,
               start: "top 75%",
            },
         }
      );
   }, []);

   const playingVideo = () => {
      return setPlayVou(true);
   };

   useEffect(() => {
      videoGsap();
      return () => videoGsap();
   }, [videoGsap]);

   useEffect(() => {
      liGsap();
      return () => liGsap();
   }, [liGsap]);

   useEffect(() => {
      storyGsap();
      return () => storyGsap();
   }, [storyGsap]);

   useEffect(() => {
      imageGsap();
      return () => imageGsap();
   }, [imageGsap]);

   return (
      <>
         <Header />
         <div className='storyPart1'>
            <div className='storyLeft' ref={leftPartRef}>
               <h3>
                  L’AGENCE FAITE POUR
                  <span>
                     <img
                        src='./assets/vou.svg'
                        alt='logo VOU'
                        id='vouStoryLeft'
                        width='auto'
                        height='auto'
                        title='Agence VOU'
                        loading='eager'
                     />
                  </span>
               </h3>
               <p>
                  VOU est une <span>agence de communication créative</span> qui
                  réunit vos besoins graphiques en print et en digital
               </p>
               <h4>NOTRE SPÉCIALITÉ ?</h4>
               <p>
                  Créer un univers de marque complet adapté à votre entreprise
               </p>
            </div>
            <img
               src='https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80'
               alt='bureau webdesign'
               width='auto'
               height='auto'
               loading='eager'
               title=''
               ref={imageStoryRef}
            />
         </div>
         <div className='titleBetween'>
            <p>
               Nous analysons votre cible afin de
               <span> comprendre vos enjeux </span>
               et d’établir des stratégies de
               <span> communication personnalisées </span>
            </p>
            <h3>NOS PÔLES D’EXPERTISE</h3>
         </div>
         <div className='storyPart2' ref={videoRef}>
            <ReactPlayer
               id='videoVou'
               data-loop='true'
               data-autoplay='true'
               data-enabled-timeout='true'
               data-hide-on-complete='true'
               width={"100%"}
               height={"100%"}
               playsinline
               playing={playVou}
               muted
               loop
               url='/videos/ANIMVOU.mp4'
               type='video/mp4'
            />
            <ul ref={liRef}>
               <li>
                  <h4>DIGITAL</h4>
                  <span />
                  <p>
                     Un site web pour assurer votre présence en ligne et
                     développer votre business. Du développement au design on se
                     charge de tout.
                  </p>

                  <p>
                     Site vitrine / E-commerce - Refonte de site internet -
                     Maintenance- Responsiv design - SEO
                  </p>
               </li>
               <li>
                  <h5>DESIGN</h5>
                  <span />
                  <p>
                     L'identité visuelle est fondamentale pour <br /> se
                     positionner et marquer l’esprit du public.
                  </p>
                  <p>
                     Logotype - Charte graphique - Univers visuel Packaging..
                  </p>
               </li>
               <li>
                  <h5>RÉSEAUX SOCIAUX</h5>
                  <span />
                  <p>
                     Pour une campagne ponctuelle ou un accompagnement sur la
                     durée,
                     <br /> nous développons une stratégie de ciblage
                     <br />
                     et analysons vos performances.
                  </p>
                  <p>
                     Audit - Stratégie marketing - Création de contenu Gestion
                     RS - Relations influenceurs
                  </p>
               </li>
               <li>
                  <h5>PRINT</h5>
                  <span />
                  <p>
                     L’identité visuelle est fondamentale pour <br />
                     se positionner et marquer l’esprit du public.
                  </p>
                  <p>
                     PLV - Plaquette commercial - Packaging - Brochure Catalogue
                     - Flyer - Carte de visite
                  </p>
               </li>
            </ul>
         </div>

         <div className='contactFooter'>
            <h3>
               Alors, que voulez-
               <span>
                  <img
                     src='./assets/vou.svg'
                     alt='logo VOU'
                     width='auto'
                     height='auto'
                     title='Agence VOU'
                     loading='eager'
                  />
               </span>
               &nbsp;?
            </h3>
            <p>
               Nous développons des solutions sur-mesure adaptées à vos besoins
               et vos ambitions
            </p>
            <Link to='/contact'>
               <button>
                  PRENONS RDV
                  <img
                     src='./assets/phone.svg'
                     alt='logo VOU'
                     width='auto'
                     height='auto'
                     loading='eager'
                     title=''
                  />
               </button>
            </Link>
         </div>
      </>
   );
}
export default Story;
