import React from "react";
import "./transitionPage.css";

function Transition({ timeline }) {
  const transi = React.useRef(null);

  React.useLayoutEffect(() => {
    timeline
      .fromTo(
        transi.current,
        {
          autoAlpha: 0,
        },
        {
          duration: 0.9,
          autoAlpha: 0.7,
        }
      )
      .to(transi.current, {
        duration: 0.5,
        autoAlpha: 0,
      });
  });

  return (
    <div>
      <img
        src='assets/simpleLogoVou.svg'
        className='transition-effect'
        alt='logo VOU'
        height='auto'
        width='auto'
        ref={transi}
      />
    </div>
  );
}

export default Transition;
