import "./footer.css";
import { Link } from "react-router-dom";
import { AiFillFacebook, AiFillInstagram } from "react-icons/ai";

function Footer() {
   return (
      <>
         <div className='container'>
            <div className='centerContainer'>
               <div className='footerHistoire'>
                  <Link to='/'>
                     <img
                        src='./assets/logoVouWhite.svg'
                        alt='logo VOU'
                        width='auto'
                        height='auto'
                        title='Agence VOU'
                        loading='eager'
                     />
                  </Link>
                  <p>
                     L’Agence VOU, située à Paris,
                     <br /> est experte dans la création de sites internet
                     sur&#8209;mesure. <br />
                     <br /> Nous vous accompagnons dans vos objectifs en
                     propulsant votre image de marque. <br />
                     <br /> Nous savons que votre projet compte pour vous, c’est
                     pourquoi notre implication dans chaque projet est total.
                  </p>
               </div>

               <div className='footerExpertise'>
                  <h4>Nos pôles d'expertise</h4>
                  <ul>
                     <li>Création de site internet vitrine</li>
                     <li>Création de site internet e&#8209;commerce</li>
                     <li>Création de site internet Wordpress</li>
                     <li>Charte graphique</li>
                     <li>Identitée visuelle</li>
                     <li>Création de logo</li>
                     <li>Packaging</li>
                     <li>Flyers</li>
                     <li>Plaquette commercial</li>
                     <li>Community management</li>
                     <li>Campagne ADS</li>
                  </ul>
               </div>

               <div className='footerLink'>
                  <h5>Liens utiles</h5>
                  <div>
                     <Link to='/conditions-generales'>
                        Conditions générales de ventes
                     </Link>
                  </div>
                  <div>
                     <h6>Vous souhaitez un site internet ? </h6>
                     <Link to='/contact'>Devis gratuit</Link>
                  </div>
                  <div className='social'>
                     <h6>Nos réseaux sociaux</h6>
                     <div className='socialLink'>
                        <a href='https://www.facebook.com/agencevou'>
                           <AiFillFacebook />
                        </a>
                        <a href='https://www.instagram.com/agencevou/'>
                           <AiFillInstagram />
                        </a>
                     </div>
                  </div>
               </div>

               <div className='footerContact'>
                  <h5>Nous contacter</h5>
                  <div>
                     <h6>Par téléphone au</h6>
                     <a href='tel:+330652856675'>06 52 85 66 75</a>
                  </div>
                  <div>
                     <h6>Ou envoyez un e-mail à</h6>

                     <a href='mailto:contact@quevoulezvou.com'>
                        contact@quevoulezvou.com
                     </a>
                  </div>
                  <div>
                     <h6>Adresse de notre agence </h6>
                     <a href='https://www.google.fr/maps/place/32+Rue+Guy+M%C3%B4quet,+92240+Malakoff/@48.8169518,2.2983923,17z/data=!3m1!4b1!4m5!3m4!1s0x47e6705f607e9699:0x866f6616b829efbc!8m2!3d48.8169483!4d2.300581'>
                        <p>
                           32 Rue Guy Môquet
                           <br /> 92240 Malakoff
                        </p>
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default Footer;
